<template lang="html">

  <section class="support support-getting-started about-us">
    <section class="slider-full">

      <div class="slide">
        <div class="d-flex jcsb container-slide container aic">
          <div class="div-text ">
            <Waypoint>
              <h1 class="title-big -whiteC">
                {{ $t('release_notes.intro') }}
              </h1>
            </Waypoint>
            <Waypoint>
              <p class="-whiteC">
                {{ $t('release_notes.texto1') }}
              </p>
            </Waypoint>
          </div>
          <div class="div-image">
            <Vue3Lottie :animationData="headSupportIntern" height="auto" width="100%" :loop="1" />
          </div>
        </div>

      </div>
    </section>
    <section class="content-info container how-video-container">
      <div class="menu-filters" v-if="Object.keys(store.releases.categories).length != 0">
        <p class="title" @click="toggleShowCategories"
          :class="[dropDownCategories == true ? 'title active-dropdrow' : 'title']">
          {{ $t('release_notes.product_area') }}</p>
        <div v-for="(categorieRelease, key ) in store.releases.categories" :key="key"
          :class="[dropDownCategories == true ? 'div-check active-dropdrow' : 'div-check']">
          <input type="checkbox" :id="categorieRelease.dataId" :value="categorieRelease.dataId"
            v-model="checkedCategories">
          <label :for="categorieRelease.dataId">{{ categorieRelease.name }}</label>
        </div>
      </div>

      <div class="container release-notes container-results">
        <p class="title-medium"> {{ $t('release_notes.download') }}
        </p>
        <div class="release-list" v-if="store.getReleases(this.page)">
          <div class="release-item" v-for="(release, key ) in store.getReleases(this.page)" :key="key">
            <a :href="release.file.url" target="_blank">
              <div class="content-link">
                <p class="title-semi-medium -blueDarkC">
                  {{ release.name }}
                </p>
                <p class="description">
                  {{ currentDateTime(release.publish_date) }}

                </p>
              </div>
              <img src="/img/icons/icon-download-pdf.svg" alt="Getting Started">
            </a>
          </div>

        </div>

        <template v-else>
          <p class="title-medium text-center title-no-result">No results found</p>
        </template>
        <paginator v-if="store.getReleases(this.page)" :pageActual="page" :totalItems="ReleaseMaxItems"
          @clicked="changePages"></paginator>
      </div>
    </section>
    <section class="other-support-container">
      <div class="container">
        <h2 class="title-medium">{{ $t('support.other') }}</h2>
        <div class="container-link">
          <router-link :to="{ 'name': 'support-how' }" class="item">
            <img src="/img/icons/how-videos-black.svg" alt=" Getting Started">
            <p class="title-semi-medium -blueDarkC">
              {{ $t('support.how_to_videos') }}
            </p>
            <p class="description">
              {{ $t('support.how_to_videos_text') }}

            </p>
          </router-link>
          <router-link :to="{ 'name': 'support-started' }" class="item">
            <img src="/img/icons/getting-stated-black.svg" alt=" Getting Started">
            <p class="title-semi-medium -blueDarkC">
              {{ $t('support.getting_started') }}
            </p>
            <p class="description">
              {{ $t('support.getting_started_text') }}
            </p>
          </router-link>

        </div>
      </div>
    </section>
  </section>

</template>

<script lang="js">
import moment from 'moment'
import paginator from '@/components/paginator.vue'
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import headSupportIntern from '@/lotties/head-support-intern.json'
import { contentStore } from '@/stores/contents'
const store = contentStore();
export default {
  name: 'support-release-notes',
  props: [],
  components: {
    Vue3Lottie,
    paginator
  },
  setup() {
    return {
      store,
      getEntries: store.getEntries,
      getReleasesPage: store.getReleasesPage,
      setPageReleases: store.setPageReleases,
    }
  },
  mounted() {
    store.loadReleases({
      page: this.page,
    })
    store.loadCategoriesReleases()
  },
  data() {
    return {
      checkedCategories: [],
      headSupportIntern,
      dropDownCategories: false,
    }
  },
  methods: {
    currentDateTime(date) {
      return moment(date).locale('en').format('L')
    },
    toggleShowCategories: function () {
      this.dropDownCategories = !this.dropDownCategories;
    },
    changePages(item) {
      this.setPageReleases(item)
      store.loadReleases(
        {
          page: this.page,
        }
      )
    },
  },
  computed: {
    page() {
      return store.getReleasesPage
    },
    ReleaseMaxItems() {
      return store.getPagesRelease();
    },
  },
  watch: {
    checkedCategories() {
      store.loadReleases(
        {
          filter: this.checkedCategories
        }
      )
    },
    "store.lang": function(newLang){
      this.changePages(1);
      store.loadCategoriesReleases();
    }
  }
}


</script>

<style scoped lang="scss">
.support-release-notes {}

.body-app {
  padding-top: 87px;
}
@media screen and (max-width:700px){
  .support.about-us .slider-full .slide {
        background: #1E2F3F;
        position: relative;
        height: 520px;
    }
}
</style>